<template>
  <div>
    <v-card
      outlined
      class="infoCards"
      id="country-ports"
    >
      <v-card-text class="my-0 py-0">
        <v-list dense subheader :key="locationKey">
          <v-subheader style="font-size: 14px">
            <v-icon class="mr-2" small>location_on</v-icon>
            Location Settings</v-subheader
          >
          <v-divider></v-divider>
          <v-list-item
            @click="(countryItemType = 'Origin'), (countryDialog = true)"
          >
            <v-list-item-action>
              <v-icon color="grey" small
                >public</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="item.originCountry">
                {{ item.originCountry.name }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px"> Origin Country </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar size="24">
                <v-img
                  contain
                  v-if="item.originCountry"
                  :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="editPort('POL')">
            <v-list-item-action>
              <v-icon :color="'grey'" small
                >anchor</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="item.polCountry && item.allOriginPorts">
                {{ item.polCountry.name }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px"> Port(s) of Loading </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="item.polCountry && item.allOriginPorts">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mr-1 mb-1" style="border:none; font-size: 11px"
                    close-icon="close"
                    small
                  >
                    <v-avatar size="24" left>
                      <v-img
                        contain
                        v-if="item.polCountry && item.polCountry.iso2"
                        :src="`https://cdn.loglive.io/flags/4x3/${item.polCountry.iso2.toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    All Ports
                  </v-chip>
                </template>
                <span>{{ item.polCountry.name }}</span>
              </v-tooltip>
              <v-list v-else subheader>
                <v-tooltip top v-for="port in loadingPorts" :key="port.id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      small
                      @click:close="removePort(port)"
                      class="mr-1 mb-1" style="border: none; font-size: 11px"
                      close-icon="close"
                    >
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          v-if="port.code"
                          :src="`https://cdn.loglive.io/flags/4x3/${port.code
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ port.code }}
                    </v-chip>
                  </template>
                  <span style="font-size: 12px">{{ port.name }}</span>
                </v-tooltip>

                <v-list-item v-if="loadingPorts.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 11px">No specified ports.</span>
                  </v-list-item-content></v-list-item
                >
              </v-list>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="editPort('POD')">
            <v-list-item-action>
              <v-icon :color="'grey'" small
                >anchor</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                v-if="item.podCountry && item.allDestinationPorts"
              >
                {{ item.podCountry.name }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px">
                Port(s) of Discharge
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="item.podCountry && item.allDestinationPorts">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mr-1 mb-1"
                    close-icon="close" style="border:none; font-size: 11px"
                    small
                  >
                    <v-avatar size="24" left>
                      <v-img
                        contain
                        v-if="item.podCountry && item.podCountry.iso2"
                        :src="`https://cdn.loglive.io/flags/4x3/${item.podCountry.iso2.toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    All Ports
                  </v-chip>
                </template>
                <span>{{ item.podCountry.name }}</span>
              </v-tooltip>
              <v-list v-else subheader>
                <v-tooltip top v-for="port in dischargePorts" :key="port.id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      small
                      @click:close="removePort(port)"
                      class="mr-1 mb-1" style="border:none; font-size: 11px"
                      close-icon="close"
                    >
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          v-if="port.code"
                          :src="`https://cdn.loglive.io/flags/4x3/${port.code
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ port.code }}
                    </v-chip>
                  </template>
                  <span style="font-size: 12px">{{ port.name }}</span>
                </v-tooltip>

                <v-list-item v-if="dischargePorts.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 11px">No specified ports.</span>
                  </v-list-item-content></v-list-item
                >
              </v-list>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="finalDestinationModal = true">
            <v-list-item-action>
              <v-icon  color="grey" small
                >public</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="item.finalDestinationCity">
                {{ item.finalDestinationCity }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px"> Final Destination </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="item.finalDestinationValue">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mr-1 mb-1"
                    close-icon="close" style="border: none;font-size: 11px"
                    small
                  >
                    <v-avatar size="24" left>
                      <v-img
                        contain
                        v-if="item.finalDestinationValue"
                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ item.finalDestinationValue }}
                  </v-chip>
                </template>
                <span>{{ item.finalDestinationCity }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item
            @click="openDocumentationPODModal(item.documentationPOD)"
          >
            <v-list-item-action>
              <v-icon color="grey" small
                >description</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle style="font-size: 11px"> Documentation POD </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="item.documentationPOD">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mr-1 mb-1" style="border: none;font-size: 11px"
                    close-icon="close"
                    pill
                    small
                  >
                    <v-avatar size="32" left>
                      <v-img
                        contain
                        v-if="item.documentationPOD"
                        :src="`https://cdn.loglive.io/flags/4x3/${item.documentationPOD
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ item.documentationPOD }}
                  </v-chip>
                </template>
                <span>{{ item.documentationPOD }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item
            @click="(countryItemType = 'Destination'), (countryDialog = true)"
          >
            <v-list-item-action>
              <v-icon
               color="grey" small
                >public</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="item.destinationCountry">
                {{ item.destinationCountry.name }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px"> Destination Country </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar size="24">
                <v-img
                  contain
                  v-if="item.destinationCountry"
                  :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="countryDialog"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"> Select {{ countryItemType }} Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="(countryDialog = false), (searchCountries = null)"
            >
            <v-icon small>close </v-icon>
            </v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            autofocus
            dense
            hide-details
            v-model="searchCountries"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="setCountry(country)"
              style="height: 30px"
            >
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 13px"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="finalDestinationModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" small>location_on</v-icon> Manage
            Final Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(finalDestinationModal = false), (portSearch = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-container class="pa-0">
              <!-- Chips Container -->
              <v-chip-group v-model="selectedTransportModes" active-class="primary--text" multiple>
                <v-chip v-for="transport in transportModes" :key="transport" :value="transport" filter small>
                  {{ transport }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </template>
          <v-text-field class="my-0 py-0" autofocus prepend-inner-icon="search" dense placeholder="Search" outlined
            clearable v-model="portSearch" hide-details></v-text-field>
          <v-progress-linear v-if="loadingPortSearch" color="primary" indeterminate></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0 mx-0 px-0">
              <v-card-text class="my-0 py-0 mx-0 px-0">
                <v-virtual-scroll v-if="portResults.length > 0" class="my-0 py-0" :bench="0" :items="portResults"
                  height="250" item-height="50">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setFinalDestination(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px; color: grey">
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px">No results found</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="portModal" persistent width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Manage Ports </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="portModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="countryItemType == 'POL'">
          <v-list dense>
            <v-row align="center">
              <v-col cols="12" sm="7">
                <v-list-item style="height: 25px">
                  <v-list-item-content>
                    <v-autocomplete class="mt-2" ref="originCountry" hide-no-data hide-selected placeholder="Country*"
                      dense outlined :items="countries" clearable item-text="name" item-value="id"
                      :menu-props="{ closeOnContentClick: true }" return-object v-model="item.polCountry">
                      <template v-slot:selection>
                        <v-avatar size="32" class="mr-2" v-if="item.polCountry">
                          <v-img contain v-if="item.polCountry.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.polCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        <span v-if="item.polCountry">
                          {{ item.polCountry.name }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img contain v-if="data.item.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="5">
                <v-list-item style="height: 25px" class="mb-4" @click="changePortType('origin')">
                  <v-list-item-action>
                    <v-icon v-if="item.allOriginPorts" color="success">check</v-icon>
                    <v-icon v-else color="red">close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> All Origin Ports </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list v-if="!item.allOriginPorts" subheader class="mx-2 px-2">
              <v-row>
                <v-col cols="12" md="8">
                  <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="entryPointLoading"
                    return-object :search-input.sync="entryPointSearch" v-model="entryPort"
                    @change="addPort('loading', entryPort)" :menu-props="{ closeOnContentClick: true }"
                    label="Search Entry Point" :items="entryPoints" item-text="name" item-value="locode"
                    :filter="filterObject">
                    <template v-slot:selection="data">
                      <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.locode }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-select dense label="Filter" outlined class="mt-2" v-model="entryFilterPoint" :items="points"
                    item-text="name" item-value="value"></v-select>
                </v-col>
              </v-row>
              <v-tooltip top v-for="port in loadingPorts" :key="port.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" outlined label close @click:close="removePort(port)" class="mr-1 mb-1"
                    close-icon="close">{{ port.code }}
                  </v-chip>
                </template>
                <span style="font-size: 12px">{{ port.name }}</span>
              </v-tooltip>
            </v-list>
          </v-list>
        </v-card-text>
        <v-card-text v-else-if="countryItemType == 'POD'">
          <v-list dense>
            <v-row align="center">
              <v-col cols="12" sm="7">
                <v-list-item style="height: 25px">
                  <v-list-item-content>
                    <v-autocomplete class="mt-2" ref="destinationCountry" hide-no-data hide-selected
                      placeholder="Country*" dense outlined :items="countries" clearable item-text="name" item-value="id"
                      :menu-props="{ closeOnContentClick: true }" return-object v-model="item.podCountry">
                      <template v-slot:selection>
                        <v-avatar size="32" class="mr-2" v-if="item.podCountry">
                          <v-img contain v-if="item.podCountry.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.podCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        <span v-if="item.podCountry">
                          {{ item.podCountry.name }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img contain v-if="data.item.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="5">
                <v-list-item style="height: 25px" class="mb-4" @click="changePortType('destination')">
                  <v-list-item-action>
                    <v-icon v-if="item.allDestinationPorts" color="success">check</v-icon>
                    <v-icon v-else color="red">close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      All Destination Ports
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list v-if="!item.allDestinationPorts" subheader class="mx-2 px-2">
              <v-row>
                <v-col cols="12" md="8">
                  <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="destinationPointLoading"
                    return-object :search-input.sync="destinationPointSearch" v-model="destinationPort"
                    @change="addPort('discharge', destinationPort)" :menu-props="{ closeOnContentClick: true }"
                    label="Search Destination Point" :items="destinationPoints" item-text="name" item-value="locode"
                    :filter="filterObject">
                    <template v-slot:selection="data">
                      <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.locode }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-select dense label="Filter" outlined class="mt-2" v-model="entryFilterPoint" :items="points"
                    item-text="name" item-value="value"></v-select>
                </v-col>
              </v-row>
              <v-tooltip top v-for="port in dischargePorts" :key="port.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" outlined label close @click:close="removePort(port)" class="mr-1 mb-1"
                    close-icon="close">{{ port.code }}
                  </v-chip>
                </template>
                <span style="font-size: 12px">{{ port.name }}</span>
              </v-tooltip>
            </v-list>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="documentationPODModal" persistent width="400px" :fullscreen="$vuetify.bre">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Point of Entry </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="documentationPODModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-radio-group v-model="selectedRadio">
                <v-radio label="Sea" value="sea"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="selectedRadio">
                <v-radio label="Air" value="air"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="selectedRadio">
                <v-radio label="Rail" value="rail"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="documentationPODLoading"
            :search-input.sync="documentationPODSearch" v-model="item.documentationPOD"
            :menu-props="{ closeOnContentClick: true }" label="Search" :items="ports" item-text="name" item-value="locode"
            :filter="filterObject">
            <template v-slot:selection="data">
              <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    countries: [],
    countryItemType: "Origin",
    countryDialog: false,
    documentationPODModal: false,
    destinationPointLoading: false,
    destinationPointSearch: null,
    destinationPoints: [],
    destinationPointTimeout: null,
    destinationPort: null,
    destinationFilterPoint: "port",
    documentationPODLoading: false,
    documentationPODSearch: null,
    documentationPODTimeout: null,

    entryPointLoading: false,
    entryPointSearch: null,
    entryPoints: [],
    entryPointTimeout: null,
    entryPort: null,
    entryFilterPoint: "port",
    finalDestinationModal: false,
    loadingPortSearch: false,
    locationKey: 100,
    points: [
      { name: "Airport", value: "airport" },
      { name: "Port", value: "port" },
      { name: "Rail", value: "rail" },
    ],
    ports: [],
    portModal: false,
    portResults: [],
    portSearch: null,
    portSearchQuery: null,
    portSearchTimeout: null,
    portType: null,
    searchCountries: null,
    selectedRadio: "sea",
    selectedTransportModes: ["Port"],
    transportModes: ["Rail", "Road", "Port", "Airport"],
  }),
  computed: {
    dischargePorts() {
      let result = this.item.consigneeProfilePorts.filter(
        (x) => x.type == "discharge"
      );
      return result;
    },
    loadingPorts() {
      let result = this.item.consigneeProfilePorts.filter(
        (x) => x.type == "loading"
      );
      return result;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
  },
  watch: {
    async destinationPointSearch(val) {
      if (this.destinationPointLoading) {
        clearTimeout(this.destinationPointTimeout);
      }
      if (val && val.length > 2) {
        this.destinationPointLoading = true;
        this.destinationPointTimeout = setTimeout(async () => {
          this.destinationPoints = await this.$API.getCountryTowns(
            this.item.podCountry.iso2,
            {
              params: {
                search: val,
                portType: this.destinationFilterPoint,
              },
            }
          );
          this.destinationPointLoading = false;
        }, 250);
      } else {
        clearTimeout(this.destinationPointTimeout);
        this.destinationPointLoading = false;
      }
    },

    async entryPointSearch(val) {
      if (this.entryPointLoading) {
        clearTimeout(this.entryPointTimeout);
      }
      if (val && val.length > 2) {
        this.entryPointLoading = true;

        this.entryPointTimeout = setTimeout(async () => {
          this.entryPoints = await this.$API.getCountryTowns(
            this.item.polCountry.iso2,
            {
              params: {
                search: val,
                portType: this.entryFilterPoint,
              },
            }
          );
          this.entryPointLoading = false;
        }, 250);
      } else {
        clearTimeout(this.entryPointTimeout);
        this.entryPointLoading = false;
      }
    },

    async documentationPODSearch(val) {
      if (this.documentationPODLoading) {
        clearTimeout(this.documentationPODTimeout);
      }
      if (val && val.length > 2) {
        this.documentationPODLoading = true;
        if (this.selectedRadio == "sea") {
          this.entryFilterPoint = "port";
        } else if (this.selectedRadio == "air") {
          this.entryFilterPoint = "airport";
        } else if (this.selectedRadio == "rail") {
          this.entryFilterPoint = "rail";
        }
        // else if(this.selectedRadio == 'all'){
        //   this.entryFilterPoint = 'All'
        // }
        this.documentationPODTimeout = setTimeout(async () => {
          this.ports = await this.$API.searchTowns({
            params: {
              search: val,
              portType: this.entryFilterPoint,
            },
          });
          this.documentationPODLoading = false;
        }, 250);
      } else {
        clearTimeout(this.documentationPODTimeout);
        this.documentationPODLoading = false;
      }
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: this.selectedTransportModes,
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 500);
    },
  },
  created() {
    this.loadCountries();
  },
  methods: {
    async addPort(type, port) {
      let obj = {
        type: type,
        name: port.name,
        code: port.locode,
      };
      this.item.consigneeProfilePorts.push(obj);

      if (this.item.id) {
        obj.consigneeProfileId = this.item.id;
        obj = await this.$API.createConsigneeProfilePort(obj);
        this.item.consigneeProfilePorts[
          this.item.consigneeProfilePorts.length - 1
        ] = obj;
      }
      this.entryPointSearch = "";
      this.entryPoints = [];
      this.entryPoint = null;
      this.destinationPointSearch = "";
      this.destinationPoints = [];
      this.destinationPoint = null;
      this.locationKey++;
    },
    async changePortType(type) {
      if (type == "origin") {
        this.item.allOriginPorts = !this.item.allOriginPorts;
      } else {
        this.item.allDestinationPorts = !this.item.allDestinationPorts;
      }
      if (this.item.id) {
        await this.$API.updateConsigneeProfile(this.item);
      }
    },
    editPort(type) {
      this.countryItemType = type;
      this.portModal = true;
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      );
    },
    async getDocumentationPodPorts(val) {
      this.documentationPODLoading = true;
      this.ports = await this.$API.searchTowns({
        params: {
          search: val,
        },
      });
      this.documentationPODLoading = false;
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
    },
    openDocumentationPODModal(val) {
      this.documentationPODModal = true;
      this.getDocumentationPodPorts(val);
    },
    setCountry(country) {
      if (this.countryItemType == "Origin") {
        this.item.originCountry = country;
        this.item.originCountryId = country.id;
      } else if (this.countryItemType == "Destination") {
        this.item.destinationCountry = country;
        this.item.destinationCountryId = country.id;
      }
      this.countryDialog = false;
      this.searchCountries = null;
      this.locationKey++;
    },
    setFinalDestination(item) {
      this.item.finalDestinationValue = item.locode;
      this.item.finalDestinationCity = item.name;
      let findCountry = this.countries.find(
        (x) => x.iso2 == this.item.finalDestinationValue.substring(0, 2)
      );
      this.item.destinationCountry = findCountry;
      this.item.destinationCountryId = findCountry.id;
      this.finalDestinationModal = false;
      this.locationKey++;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
</style>